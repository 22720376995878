<template>
  <div class="myProfileWrapper">
    <b-card>
      <b-row>
        <b-col md="12">
          <div class="mb-2 myProfileImgWrapper" style="position:relative;">
            <img style="height: 149px; width: 149px; border-radius: 10px;"
              :src="image"
              alt="image"
              height="100px"
              width="100px"
            />
            <input
              type="file"
              id="file"
              accept="image/*"
              @input="selectProfile"
              style="display: none"
            />
            <div class="upload-area-div">
              <span style="color: #fff;">Upload</span>
              <label for="file"><img src="@/assets/images/Vector.png" for="file" style="cursor: pointer;"/></label>
            </div>
            <!-- <input type="file" accept="image/*"  @input="selectProfile"> -->
          </div>
          <span style="color: red; width: 100%" v-if="profileError != ''">{{
            profileError
          }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <validation-observer ref="profileForm">
            <form ref="form">
               <b-row class="align-items-baseline">
              <b-col md="4" class="d-block float-left">
                <validation-provider
                  #default="{ errors }"
                  name="first name"
                  rules="required|min:3|alpha"
                >
                  <b-form-group label="First Name*">
                    <b-form-input
                      :maxlength="20"
                      v-model="firstName"
                      @blur="firstName = firstName.trim()"
                      autocomplete="off"
                      required
                    />
                    <small class="text-danger" style="display: block">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="d-block float-left">
                <validation-provider
                  #default="{ errors }"
                  name="last name"
                  rules="required|min:3|alpha"
                >
                  <b-form-group label="Last Name*">
                    <b-form-input
                      :maxlength="20"
                      v-model="lastName"
                      @blur="lastName = lastName.trim()"
                      autocomplete="off"
                      required
                    />
                    <small class="text-danger" style="display: block">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="d-block float-left" style="margin-top: 25px; text-align: right">
                <b-form-group>
                  <b-button
                    variant="primary"
                    style="margin-right: 29px; cursor: pointer !important"
                    @click="saveProfile()"
                    :disabled="showSpinner"
                    >SAVE</b-button
                  >
                  <b-button
                    variant="outline-secondary"
                    style="cursor: pointer !important"
                    @click="cancelProfile()"
                    >CANCEL</b-button
                  >
                </b-form-group>
              </b-col>
               </b-row>
              <b-col
                md="12"
                v-if="showSpinner"
                class="d-flex justify-content-center"
              >
                <b-spinner v-if="showSpinner" style="width: 50px; height: 50px" />
              </b-col>
            </form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { uploadFile } from "@/utils/FirebaseQueries/storageQueries";
import { dbCollections, storageCollections } from "@/utils/firebaseCollections";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@validations';
export default {
  name: "my-profile",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      firstName: "",
      lastName: "",
      image: "",
      profileError: "",
      localObject: {},
      showSpinner: false,
      userDataObject : {}
    };
  },
  created() {
    var self = this;
    self.showSpinner = true;
    self.localObject = {};
    self.localObject = JSON.parse(localStorage.getItem("currentUser"));
    self.userDataObject = JSON.parse(localStorage.getItem("userData"));
    self.firstName = self.localObject.fullName.split(" ")[0];
    self.lastName = self.localObject.fullName.split(" ")[1];
    self.image = self.localObject.avatar ? self.localObject.avatar : '';
    self.showSpinner = false;
  },
  methods: {
    selectProfile(event) {
      var self = this;
      self.showSpinner = true;
      self.profileError = "";
      let files = event.target.files;
      // console.log(files[0],files[0].name,'file');
      if (files[0].type.toLowerCase().includes("image")) {
        if (files[0].size <= 2000000) {
          self.profileError = "";
          uploadFile(`${storageCollections.BANNERS}`, files[0].name, files[0])
            .then((url) => {
              // console.log(url, "url");
              self.image = url;
              self.showSpinner = false;
            })
            .catch((error) => {
              self.showSpinner = false;
              console.log("ERROR in get storage url in my profile page", error);
            });
        } else {
          self.profileError = "File size is not allowed more then 2mb";
          self.showSpinner = false;
        }
      } else {
        self.profileError = "Only .jpg,.jpeg,.png,.gif files are allowed";
        self.showSpinner = false;
      }
    },
    saveProfile() {
      debugger; // eslint-disable-line
      var self = this;
      self.$refs.profileForm.validate().then((valid) => {
        if (valid) {
          if (self.profileError == "") {
            let updateObject = {
              firstName: self.firstName,
              lastName: self.lastName,
              profilePicture: self.image,
            };
            updateFirebase
              .updateRootCollectionDataByDocId(
                dbCollections.USERS,
                self.localObject.id,
                updateObject,
                (res) => {
                //   console.log(res, "res");
                  self.localObject.fullName = `${self.firstName.trim()} ${self.lastName.trim()}`;
                  self.localObject.username = `${self.firstName.trim()} ${self.lastName.trim()}`;
                  self.localObject.avatar = self.image;
                  localStorage.setItem(
                    "currentUser",
                    JSON.stringify(self.localObject)
                  );
                  self.userDataObject.fullName = `${self.firstName.trim()} ${self.lastName.trim()}`;
                  self.userDataObject.username = `${self.firstName.trim()} ${self.lastName.trim()}`;
                  self.userDataObject.avatar = self.image;
                  localStorage.setItem(
                    "userData",
                    JSON.stringify(self.userDataObject)
                  );
                  self.$toast.success("Profile updated successfully");
                  self.$root.$emit('updateHeader');
                  self.showSpinner = false;
                }
              )
              .catch((error) => {
                self.showSpinner = false;
                console.log(
                  "ERROR in update profile data in user collection",
                  error
                );
              });
          } else {
            self.showSpinner = false;
          }
        }
      });
    },
    cancelProfile() {
      var self = this;
      self.showSpinner = false;
      self.profileError = "";
      self.firstName = self.localObject.fullName.split(" ")[0];
      self.lastName = self.localObject.fullName.split(" ")[1];
      self.image = self.localObject.avatar;
      self.$refs.profileForm.reset();
    },
  },
};
</script>